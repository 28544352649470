import request from "./axios"

// 接口请求示例
export function noticeQuery(data) {
    return request({
        url: '/global/notice/query',
        method: 'POST',
        data
    })
}
export function noticeDetail(data) {
  return request({
      url: '/global/notice/detail',
      method: 'POST',
      data
  })
}
export function noticeRead(data) {
  return request({
      url: '/global/notice/view',
      method: 'POST',
      data
  })
}
export function verify(data) {
  return request({
      url: '/global/verify/verify',
      method: 'POST',
      data
  })
}

export function verifyDetail(data) {
  return request({
      url: '/global/verify/detail',
      method: 'POST',
      data
  })
}


export function count() {
  return request({
      url: '/global/appUser/count',
      method: 'GET'
  })
}

export function verifyQuery(data) {
  return request({
      url: '/global/verify/query',
      method: 'POST',
      data
  })
}


export function preApply(data) {
  return request({
      url: '/withdraw/pre-apply',
      method: 'POST',
      data
  })
}


export function apply(data) {
  return request({
      url: '/withdraw/apply',
      method: 'POST',
      data
  })
}



export function user(data) {
  return request({
      url: '/api/users/app-user-info',
      method: 'POST',
      data
  })
}

export function canWithdraw(data) {
  return request({
      url: '/withdraw/canWithdraw',
      method: 'POST',
      data
  })
}

export function batchWithdraw(data) {
  return request({
      url: '/withdraw/batchWithdraw',
      method: 'POST',
      data
  })
}


export function uploadFiles(data) {
  return request({
      url: '/file/salesDocument',
      method: 'POST',
      data
  })
}

export function deleteFiles (data) {
  return request({
      url: '/withdraw/deleteFiles',
      method: 'POST',
      data
  })
}

// 图片展示--获取  销售凭证
export function downloadSalesDocument (data) {
  return request({
      url: '/file/download/salesDocument',
      method: 'POST',
      data
  })
}


// 销售凭证 查询配置
export function salesDocumentConfigQuery(data) {
  return request({
    url: `/global/salesDocumentConfig/query`,
    method: 'POST',
    data
  })
}


// 获取结算订单卡片列表
export function getSettleAccountList(data) {
  return request({
    url: '/global/batchAgent/list',
    method: 'POST',
    data
  })
}

// 获取销售明细列表
export function getSaleDetailsList(data) {
  return request({
    url: 'global/saleDetail/list',
    method: 'POST',
    data
  })
}


// 获取结算详情
export function getSettleDetails(data) {
  return request({
    url: '/global/batchAgent/batchOrderInfo',
    method: 'POST',
    data
  })
}