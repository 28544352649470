<template>
  <div :class="['nodata-container', isSmallSize ? 'small' : 'big']">
    <div class="nodata">
      <div class="img-container">
        <img src="assets/nodata.png" />
      </div>
      <div class="nodata-desc">{{ $t("mine.none") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSmallSize: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="less" scoped>
.small {
  height: 230px;
}

.big {
  height: 450px;
}



.nodata-container {
  display: flex;
  justify-content: center;
  align-items: center;
  
  .nodata {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 88px;


    .img-container {
      width: 96px;
      height: 62px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    
    .nodata-desc {
      color: #CCCCCC;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
    }
  }
}
</style>