import i18n from '@/lang'

 //base64转file
export const base64ImgtoFile = (dataurl, filename = 'file') => {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
          type: mime
      })
  }

// 将二维数组或对象转为map结构
export const createMap = (initialData) => {
  if (Array.isArray(initialData) && initialData.every(item => Array.isArray(item) && item.length === 2)) {
    return new Map(initialData);
  }
    
  if (typeof initialData === 'object' && initialData !== null) {    
    return new Map(Object.entries(initialData));  
  }
  return new Map();
}

      
// S: 成功  F/R: 失败  P/W/A: 进行中
export const IMG_MAP= createMap({
  "S": "assets/ic_success.png",
  "F": "assets/ic_failed.png",
  "R": "assets/ic_failed.png",
  "P": "assets/ic_Process.png",
  "W": "assets/ic_Process.png",
  "A": "assets/ic_Process.png",
})

export const getTextMap = () => createMap({
  "S": i18n.t("settle.settled"),
  "F": i18n.t("settle.settleFailed"),
  "R": i18n.t("settle.settleFailed"),
  "P": i18n.t("settle.settling"),
  "W": i18n.t("settle.settling"),
  "A": i18n.t("settle.settling"),
})
