<template>
  <div class="settle-order" @click="handleSettleOrderClick(settleItem)">
    <div class="settle-item-top">
      <span>{{ $t("settle.settleTime") + "：" }}</span>
      <span class="settle-item-time">{{ settleItem.createTime }}</span>
    </div>
    <div class="settle-item-bottom">
      <div class="settle-item-count">
        <div>{{ $t("settle.settleCount") }}</div>
        <div class="settle-item-num">{{ settleItem.saleNumber }}</div>
      </div>
      <div class="settle-item-amount">
        <div>{{ $t("settle.settleAmount") }}</div>
        <div class="settle-item-num">{{ settleItem.amount }}</div>
      </div>
      <div class="settle-item-icon">
        <div class="settle-item-container">
          <img :src="statusImgMap.get(settleItem.orderStatus)" alt="">
        </div>
        <div class="settle-item-text">{{ getTextMap().get(settleItem.orderStatus) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { IMG_MAP, getTextMap } from '@network/utils.js'
export default {
  props: {
    settleItem: {
      type: Object,
      default: () => ({})
    },
    canNavigate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      statusImgMap: IMG_MAP,
    }
  },
  methods: {
    handleSettleOrderClick(settleItem) {
      if(this.canNavigate) {
        this.$router.push({
          name: 'settleDetail',
          params: { id: settleItem.orderNo }
        })
      }
    },
    getTextMap,
  },
}
</script>

<style lang="less" scoped>
.settle-order {
  border-radius: 10px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);

  &:first {
    margin-top: 9px;
  }

  .settle-item-top {
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
    border-radius: 10px 10px 0 0;
    background-color: rgb(247, 247, 248);
    color: rgb(51, 51, 51);
  }

  .settle-item-bottom {
    display: flex;
    // height: 80px;
    padding: 15px 20px;
    color: rgb(102, 102, 102);
    margin-bottom: 20px;
    font-size: 13px;
    justify-content: space-between;
    align-items: center;

    .settle-item-count, .settle-item-amount, .settle-item-icon {
      display: flex;
      // height: 50px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .settle-item-num {
      margin-top: 5px;
      font-size: 24px;
      color: #333333;
    }

    .settle-item-icon {
      width: 50px;
      .settle-item-container {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .settle-item-text {
        margin-top: 5px;
        color: rgb(153, 153, 153);
      }
    }
  }
}

</style>